import { css } from 'styled-components';

const headerStyle = css`
  font-family: ${({ theme }) => theme.fonts.primary};
  letter-spacing: -0.025em;
  margin-top: 3rem;
`;

export const header1Style = css`
  ${headerStyle};
  font-size: 3rem;
  line-height: 1;
`;

export const header2Style = css`
  ${headerStyle}
  font-size: 2.25rem;
  line-height: 2.5rem;
`;

export const header3Style = css`
  ${headerStyle}
  font-size: 1.875rem;
  line-height: 2.25rem;
`;

export const header4Style = css`
  ${headerStyle}
  font-size: 1.5rem;
  line-height: 2rem;
`;

export const header5Style = css`
  ${headerStyle}
  font-size: 1.25rem;
  line-height: 1.75rem;
`;

export const header6Style = css`
  ${headerStyle}
  font-size: 1.125rem;
  line-height: 1.75rem;
`;

export const textStyle = css`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin: 0 auto;
`;

export const smallTextStyle = css`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0 auto;
`;
