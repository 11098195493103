import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
// import { Link } from 'gatsby';
import Helmet from 'react-helmet';

import GlobalStyle from 'styles/GlobalStyle';
import theme from 'styles/theme';
import useSiteMetadata from 'hooks/useSiteMetadata';

const Layout = ({ children }) => {
  const { title, description } = useSiteMetadata();
  const date = new Date();
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
      </Helmet>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <StyledWrapper>
          {/* <header>
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/typography">Typography</Link>
          </header> */}
          <main>{children}</main>
          <footer>©️ Ting Chang {date.getUTCFullYear()}</footer>
        </StyledWrapper>
      </ThemeProvider>
    </>
  );
};

const StyledWrapper = styled.div`
  margin: auto 2rem;
  footer {
    text-align: center;
    margin: 20px;
  }
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
