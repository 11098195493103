import * as typography from 'styles/typography';

const theme = {
  breakpoints: {
    tablet: '960px',
    mobile: '600px',
  },
  fonts: {
    primary: 'Open Sans',
    secondary: 'Crimson Text',
  },
  header1Style: typography.header1Style,
  header2Style: typography.header2Style,
  header3Style: typography.header3Style,
  header4Style: typography.header4Style,
  header5Style: typography.header5Style,
  header6Style: typography.header6Style,
  textStyle: typography.textStyle,
  smallTextStyle: typography.smallTextStyle,
};

export default theme;
