import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStyle = createGlobalStyle`
  ${normalize}
  * {
    line-height: 1.5rem;
    margin-top: 0;
    margin-bottom: 1.5rem; // set as base line height
  }
  h1 {
    ${({ theme }) => theme.header1Style};
  }
  h2 {
    ${({ theme }) => theme.header2Style};
  }
  h3 {
    ${({ theme }) => theme.header3Style};
  }
  h4 {
    ${({ theme }) => theme.header4Style};
  }
  h5 {
    ${({ theme }) => theme.header5Style};
  }
  h6 {
    ${({ theme }) => theme.header6Style};
  }
  body {
    ${({ theme }) => theme.fonts.primary};
  }
  p {
    ${({ theme }) => theme.textStyle};
  }
  p + p {
    text-indent: 1em;
  }
`;

export default GlobalStyle;
